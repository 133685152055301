var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [!_vm.readOnly ? _c('v-row', {
    staticStyle: {
      "height": "35px"
    }
  }, [_c('v-spacer'), _c('template-editor-actions', {
    ref: "editorActions",
    staticClass: "mr-3",
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "partnerId": _vm.partnerId,
      "editor": _vm.editor,
      "editConfig": _vm.editConfig
    },
    on: {
      "click": _vm.handleEdit,
      "addPlaceholder": _vm.addPlaceholderToBody
    }
  }, [_vm._t("default")], 2)], 1) : _vm._e(), _c('v-card', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "flat": ""
    }
  }, [_vm.label ? _c('div', {
    staticClass: "mb-4",
    staticStyle: {
      "background-color": "inherit"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "background-color": "inherit",
      "height": "0"
    }
  }, [_c('small', {
    class: _vm.dynamicLabelClasses,
    staticStyle: {
      "position": "relative",
      "left": "10px",
      "bottom": "11px",
      "background-color": "inherit",
      "padding": "2px"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])])]) : _vm._e(), _c('editor-content', {
    ref: "editor",
    class: _vm.dynamicTipTapClasses,
    attrs: {
      "editor": _vm.editor
    },
    on: {
      "mouseover": function mouseover($event) {
        return _vm.setMouseOver(true);
      }
    }
  }), !_vm.readOnly ? _c('v-card-actions', {
    staticClass: "mt-n2 mb-n12"
  }, [_c('v-spacer'), _c('small', {
    style: _vm.bodyCounterStyle
  }, [_vm._v(" " + _vm._s(_vm.bodyLength) + "/50000 ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }