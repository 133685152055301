

































































































































































































































import { Component, Vue } from "vue-property-decorator";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { ReportModule } from "@/store/modules/report.store";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: { LayoutSimple }
})
export default class Terms extends Vue {
  get partner(): PartnerEntity {
    return ReportModule?.partner;
  }

  get agbUrl(): string | undefined {
    return this.partner?.settings?.agbUrl?.link;
  }

  mounted() {
    if (this.agbUrl && AppContextEnum.REPORT_PORTAL !== ConfigModule.appContext) {
      window.open(this.agbUrl, "_self");
    }
  }
}
