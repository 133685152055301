





































































import TemplateEditorPlaceholderDropdown from "@/components/template/TemplateEditorPlaceholderDropdown.vue";
import SideCard from "@/components/utility/SideCard.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import DocumentDetailCardEditor from "./DocumentDetailCardEditor.vue";
import DocumentTemplateDetailSave from "./DocumentTemplateDetailSave.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import { ISignDocument } from "@/models/sign-document.entity";

enum EditSteps {
  TOKEN,
  INITIAL,
  SIGNATURE,
  TEXT_FIELD
}
@Component({
  components: { SideCard, TemplateEditorPlaceholderDropdown, Tooltip, DocumentTemplateDetailSave }
})
export default class DocumentTemplateDetailTokenListOptions extends mixins(Vue) {
  @Prop()
  document!: ISignDocument;

  @Prop()
  getDetailCardEditor!: () => DocumentDetailCardEditor;

  @Prop({ default: true })
  showPlaceholderOption!: boolean;

  @Prop({ default: true })
  showSave!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  editStep = EditSteps.INITIAL;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get EditSteps() {
    return EditSteps;
  }

  get options() {
    const options: { icon: string; text: string; short: string; disabled?: boolean; action: () => void }[] = [];

    if (this.showPlaceholderOption) {
      options.push({
        icon: "mdi-keyboard-space",
        text: this.$t("sign.ReportDocumentSignRequestStepperForm.addToken").toString(),
        short: this.$t("sign.ReportDocumentSignRequestStepperForm.addTokenShort").toString(),
        action: !this.disabled
          ? this.startAddToken
          : () => {
              return;
            }
      });
    }

    options.push({
      icon: "mdi-signature-freehand",
      text: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN)
        ? this.$t("sign.ReportDocumentSignRequestStepperForm.addSignature").toString()
        : this.$t("sign.ReportDocumentSignRequestStepperForm.addSignaturePermissionMissing").toString(),
      short: this.$t("sign.ReportDocumentSignRequestStepperForm.addSignatureShort").toString(),
      action:
        !this.disabled && UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN)
          ? this.startAddSignature
          : () => {
              return;
            },
      disabled: !UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN)
    });

    options.push({
      icon: "mdi-form-textbox",
      text: this.$t("sign.ReportDocumentSignRequestStepperForm.addTextField").toString(),
      short: this.$t("sign.ReportDocumentSignRequestStepperForm.addTextFieldShort").toString(),
      action: !this.disabled
        ? this.startAddTextField
        : () => {
            return;
          }
    });

    return options;
  }

  addField() {
    let position = -1;
    const detailCardEditor = this.getDetailCardEditor();
    if (this.editStep === EditSteps.TOKEN) {
      position = detailCardEditor.addToken().position;
    } else if (this.editStep === EditSteps.SIGNATURE) {
      position = detailCardEditor.addSignature().position;
    } else if (this.editStep === EditSteps.TEXT_FIELD) {
      position = detailCardEditor.addTextField().position;
    }

    DocumentTemplateModule.setHighlightedToken(position);

    this.editStep = EditSteps.INITIAL;
  }

  startAddToken() {
    const detailCardEditor = this.getDetailCardEditor();
    detailCardEditor.showSelect();

    this.editStep = EditSteps.TOKEN;

    DocumentTemplateModule.setHighlightedToken(-1);
  }

  startAddSignature() {
    const detailCardEditor = this.getDetailCardEditor();
    detailCardEditor.showSelect();

    this.editStep = EditSteps.SIGNATURE;

    DocumentTemplateModule.setHighlightedToken(-1);
  }

  startAddTextField() {
    const detailCardEditor = this.getDetailCardEditor();
    detailCardEditor.showSelect();

    this.editStep = EditSteps.TEXT_FIELD;

    DocumentTemplateModule.setHighlightedToken(-1);
  }

  discard() {
    const detailCardEditor = this.getDetailCardEditor();
    detailCardEditor.hideSelect();

    this.editStep = EditSteps.INITIAL;
  }

  save() {
    this.$emit("save");
  }
}
